import React, {useEffect, useState} from 'react'
import { auth, db } from "./firebase"
import {  redirect } from 'react-router-dom'
import { useStateValue } from "./StateProvider"
import { actionTypes } from "./reducer"
import { query, doc, onSnapshot, updateDoc } from 'firebase/firestore'

export const AuthContext = React.createContext()

export const AuthProvider = ({ children }) => {
    const [state, dispatch] = useStateValue()
    const [currentUser, setCurrentUser] = useState(null)
    const [pending, setPending] = useState(true)
    useEffect(()=> {
        auth.onAuthStateChanged((user) => {
                setCurrentUser(user)
                dispatch({
                    type: actionTypes.SET_USER,
                    user: user
                })
                const q = query(doc(db, `users/${user?.uid}`))
                onSnapshot(q, (snapshot)=>{
                    const newUserData = {
                        emoji: '😱',
                        feedFilter: 'recent',
                        following: [{uid: user?.uid, displayName: user?.displayName}],
                        followers: [{uid: user?.uid, displayName: user?.displayName}],
                        blocking: [],
                        blockedBy: [],
                        notifications: [],
                        dorkMode: false,
                        notificationsToken: [],
                        avatar: user?.photoURL,
                        displayName: user?.displayName
                    }
                    if (typeof snapshot?.data().emoji === "undefined") {
                        updateDoc(q, newUserData)
                        dispatch({
                            type: actionTypes.SET_USERPROFILE,
                            profile: newUserData
                        })               
                    } else {
                        dispatch({
                            type: actionTypes.SET_USERPROFILE,
                            profile: snapshot.data()
                        })   
                    }
                    
                })    
                setPending(false)
                redirect('/')    
            
        })
    },[])







    if (pending) {
        return <span style={{backgroundColor:"red"}}></span>
    }

    return (
        <AuthContext.Provider value={{currentUser}}>
            {children}
        </AuthContext.Provider>
    )
}
