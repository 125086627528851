import db from "./firebase";
import { collection, getDocs, doc, getDoc, query, orderBy, limit, startAfter, where, documentId } from "firebase/firestore"; 


export const Posts = {

  postsFirstBatch: async function () {
    try {
      const data = await getDocs(query(collection(db, "posts"), orderBy("timestamp", "desc"), limit(10)))
      let posts = [];
      let countReplies = 1
      let replies=[];
      let lastKeyP = "";
      let lastKeyR = ""
      data.forEach((doc) => {
        let payload = doc.data()
        payload.id = doc.id
        posts.push(
            payload
            );
        lastKeyP = doc.data().timestamp;
        if(typeof doc.data().replies !== "number") {
        } else {
          countReplies = countReplies + doc.data().replies
        }
      })
      if (countReplies === 0) {
        countReplies = 1
      }

      const data2 = await getDocs(query(collection(db, "replies"), orderBy('timestamp', 'desc'), limit(countReplies)))
      data2.forEach((reply)=>{
          let payload = reply.data()
          payload.id = reply.id
          replies.push(payload)
          lastKeyR = reply.data().timestamp        
    })
    return { posts, lastKeyP, lastKeyR, replies};
     
      
    } catch (e) {
      console.log(e);
    }
  },
  postsNextBatch: async (keyP, keyR) => {
    try {
      
      const data = await getDocs(query(collection(db, "posts"), orderBy("timestamp", "desc"), limit(10), startAfter(keyP)))
      let posts = [];
      let replies=[];
      let countReplies = 1
      let lastKeyP = "";
      let lastKeyR = ""
      data.forEach((doc) => {
        let payload = doc.data()
        payload.id = doc.id
        posts.push(
            payload
        );
        lastKeyP = doc.data().timestamp;
        if(typeof doc.data().replies !== "number") {
        } else {
          countReplies = countReplies + doc.data().replies
        }
      })
      if (countReplies === 0) {
        countReplies = 1
      }
        const data2 = await getDocs(query(collection(db, "replies"), orderBy("timestamp", "desc"), limit(countReplies), startAfter(keyR)))
        data2.forEach((reply)=>{
            let payload = reply.data()
            payload.id = reply.id
            replies.push(payload)
            lastKeyR = reply.data().timestamp
        })
      return { posts, lastKeyP, lastKeyR, replies};
      
    } catch (e) {
      console.log(e);
    }
  },
  postsBoostedFirstBatch: async function (following) {
    try {
      const data = await getDocs(query(collection(db, "posts"), where("boostedBy", "array-contains-any", following), orderBy("timestamp", "desc"), limit(10)))
      let posts = [];
      let replies=[];
      let countReplies = 1
      let lastKeyP = "";
      let lastKeyR = ""
      data.forEach((doc) => {
        let payload = doc.data()
        payload.id = doc.id
        posts.push(
            payload
            );
        lastKeyP = doc.data().timestamp;
        
        if(typeof doc.data().replies !== "number") {
        } else {
          countReplies = countReplies + doc.data().replies
        }
      })
      if (countReplies === 0) {
        countReplies = 1
      }
      const postIds = []
      for (let i = 0; i < posts.length; i++ ) {
        postIds.push(posts[i].id)
        
      }
      console.log('postIds: ', postIds)
      const data2 = await getDocs(query(collection(db, "replies"), where("replyingToPost", "in", postIds), orderBy('timestamp', 'desc'), limit(countReplies)))
      data2.forEach((reply)=>{
          let payload = reply.data()
          payload.id = reply.id
          replies.push(payload)
          lastKeyR = reply.data().timestamp
      })
      return { posts, lastKeyP, lastKeyR, replies};
     
      
    } catch (e) {
      console.log(e);
    }
  },
  postsBoostedNextBatch: async (keyP, keyR, following) => {
    try {
      const data = await getDocs(query(collection(db, "posts"), where("boostedBy", "array-contains-any", following), orderBy("timestamp", "desc"), limit(12), startAfter(keyP)))
      let posts = [];
      let replies=[];
      let lastKeyP = "";
      let lastKeyR = ""
      let countReplies = 1

      data.forEach((doc) => {
        let payload = doc.data()
        payload.id = doc.id
        posts.push(
            payload
        );
        lastKeyP = doc.data().timestamp;
        if(typeof doc.data().replies !== "number") {
        } else {
          countReplies = countReplies + doc.data().replies
        }
      })
      if (countReplies === 0) {
        countReplies = 1
      }
      const postIds = []
      for (let i = 0; i < posts.length; i++ ) {
        postIds.push(posts[i].id)
        
      }
      console.log('postIds: ', postIds)
        const data2 = await getDocs(query(collection(db, "replies"), where("replyingToPost", "in", postIds), orderBy('timestamp', 'desc'), limit(countReplies), startAfter(keyR)))
        data2.forEach((reply)=>{
            let payload = reply.data()
            payload.id = reply.id
            replies.push(payload)
            lastKeyR = reply.data().timestamp
        })
        return { posts, lastKeyP, lastKeyR, replies};
      
    } catch (e) {
      console.log(e);
    }
  },
  postsFollowingFirstBatch: async function (following, uid) {
    try {
      const data = await getDocs(query(collection(db, "posts"), where("uid", "in", following), orderBy("timestamp", "desc"), limit(10)))
      let posts = [];
      let replies=[];
      let countReplies = 1
      let lastKeyP = "";
      let lastKeyR = ""
      data.forEach((doc) => {
        let payload = doc.data()
        payload.id = doc.id
        posts.push(
            payload
            );
        lastKeyP = doc.data().timestamp;
        
        if(typeof doc.data().replies !== "number") {
        } else {
          countReplies = countReplies + doc.data().replies
        }
      })
      if (countReplies === 0) {
        countReplies = 1
      }
      const postIds = []
      for (let i = 0; i < posts.length; i++ ) {
        postIds.push(posts[i].id)
        
      }
      const data2 = await getDocs(query(collection(db, "replies"), where("replyingToPost", "in", postIds), orderBy('timestamp', 'desc'), limit(countReplies)))
      data2.forEach((reply)=>{
          let payload = reply.data()
          payload.id = reply.id
          replies.push(payload)
          lastKeyR = reply.data().timestamp
      })
      return { posts, lastKeyP, lastKeyR, replies};
     
      
    } catch (e) {
      console.log(e);
    }
  },
  postsFollowingNextBatch: async (keyP, keyR, following, uid) => {
    try {
      const data = await getDocs(query(collection(db, "posts"), where("uid", "in", following), orderBy("timestamp", "desc"), limit(10), startAfter(keyP)))
      let posts = [];
      let replies=[];
      let lastKeyP = "";
      let lastKeyR = ""
      let countReplies = 1

      data.forEach((doc) => {
        let payload = doc.data()
        payload.id = doc.id
        posts.push(
            payload
        );
        lastKeyP = doc.data().timestamp;
        if(typeof doc.data().replies !== "number") {
        } else {
          countReplies = countReplies + doc.data().replies
        }
      })
      if (countReplies === 0) {
        countReplies = 1
      }
      const postIds = []
      for (let i = 0; i < posts.length; i++ ) {
        postIds.push(posts[i].id)
        
      }
        const data2 = await getDocs(query(collection(db, "replies"), where("replyingToPost", "in", postIds), orderBy('timestamp', 'desc'), limit(countReplies), startAfter(keyR)))
        data2.forEach((reply)=>{
            let payload = reply.data()
            payload.id = reply.id
            replies.push(payload)
            lastKeyR = reply.data().timestamp
        })
        return { posts, lastKeyP, lastKeyR, replies};
      
    } catch (e) {
      console.log(e);
    }
  },
  postsByTagFirstBatch: async function (tag) {
    try {
      const data = await getDocs(query(collection(db, "posts"), where("themes", "array-contains", tag), orderBy("timestamp", "desc"), limit(10)))
      let posts = [];
      let replies=[];
      let lastKeyP = "";
      let lastKeyR = ""
      let countReplies = 1

      data.forEach((doc) => {
        let payload = doc.data()
        payload.id = doc.id
        posts.push(
            payload
            );
        lastKeyP = doc.data().timestamp;
        if(typeof doc.data().replies !== "number") {
        } else {
          countReplies = countReplies + doc.data().replies
        }
      })
      if (countReplies === 0) {
        countReplies = 1
      }
      const postIds = []
      for (let i = 0; i < posts.length; i++ ) {
        postIds.push(posts[i].id)
        
      }
      const data2 = await getDocs(query(collection(db, "replies"),  where("replyingToPost", "in", postIds), orderBy('timestamp', 'desc'), limit(countReplies)))
      data2.forEach((reply)=>{
          let payload = reply.data()
          payload.id = reply.id
          replies.push(payload)
          lastKeyR = reply.data().timestamp
    })
    return { posts, lastKeyP, lastKeyR, replies};
     
      
    } catch (e) {
      console.log(e);
    }
  },
  postsByTagNextBatch: async (keyP, keyR, tag) => {
    try {
      const data = await getDocs(query(collection(db, "posts"), where("themes", "array-contains", tag), orderBy("timestamp", "desc"), limit(10), startAfter(keyP)))
      let posts = [];
      let replies=[];
      let lastKeyP = "";
      let lastKeyR = ""
      let countReplies = 1

      data.forEach((doc) => {
        let payload = doc.data()
        payload.id = doc.id
        posts.push(
            payload
        );
        lastKeyP = doc.data().timestamp;
        if(typeof doc.data().replies !== "number") {
        } else {
          countReplies = countReplies + doc.data().replies
        }
      })
      if (countReplies === 0) {
        countReplies = 1
      }
      const postIds = []
      for (let i = 0; i < posts.length; i++ ) {
        postIds.push(posts[i].id)
        
      }
        const data2 = await getDocs(query(collection(db, "replies"), where("replyingToPost", "in", postIds), orderBy('timestamp', 'desc'), limit(countReplies), startAfter(keyR)))
        data2.forEach((reply)=>{
            let payload = reply.data()
            payload.id = reply.id
            replies.push(payload)
            lastKeyR = reply.data().timestamp
        })          
        return { posts, lastKeyP, lastKeyR, replies};
      
    } catch (e) {
      console.log(e);
    }
  },

  // get only one doc here
  postsByIdFirstBatch: async function (id) {
    try {
      const data = []
      const append = await getDoc(query(doc(db, `/posts/${id}`))).then((doc)=>{
        data.push(doc)
      })
      let posts = [];
      let replies=[];
      let lastKeyP = "";
      let lastKeyR = ""
      let countReplies = 1

      data.forEach((doc) => {
        let payload = doc.data()
        payload.id = doc.id
        posts.push(
            payload
            );
        lastKeyP = doc.data().timestamp;
        if(typeof doc.data().replies !== "number") {
        } else {
          countReplies = countReplies + doc.data().replies
        }
      })
      if (countReplies === 0) {
        countReplies = 1
      }
      // where replyingto == post, somehow
      const data2 = await getDocs(query(collection(db, "replies")))
      data2.forEach((reply)=>{
          let payload = reply.data()
          payload.id = reply.id
          replies.push(payload)
          lastKeyR = reply.data().timestamp
    })
    return { posts, lastKeyP, lastKeyR, replies};
     
      
    } catch (e) {
      console.log(e);
    }
  },
  postsByIdNextBatch: async (keyP, keyR, id) => {
    try {
      const data = await getDocs(query(collection(db, "posts"), where(documentId(), "==", id), limit(10), startAfter(keyP)))
      let posts = [];
      let replies=[];
      let lastKeyP = "";
      let lastKeyR = ""
      let countReplies = 1

      data.forEach((doc) => {
        let payload = doc.data()
        payload.id = doc.id
        posts.push(
            payload
        );
        lastKeyP = doc.data().timestamp;
        if(typeof doc.data().replies !== "number") {
        } else {
          countReplies = countReplies + doc.data().replies
        }
        if (countReplies === 0) {
          countReplies = 1
        }
        })
        const data2 = await getDocs(query(collection(db, "replies"), orderBy('timestamp', 'desc'), startAfter(keyR)))
        data2.forEach((reply)=>{
            let payload = reply.data()
            payload.id = reply.id
            replies.push(payload)
            lastKeyR = reply.data().timestamp
      })
      return { posts, lastKeyP, lastKeyR, replies};
      
    } catch (e) {
      console.log(e);
    }
  },

  postsByProfileIdFirstBatch: async function (id) {
    try {


      const data = await getDocs(query(collection(db, "posts"), where("uid", "==", id), orderBy("timestamp", "desc"), limit(10)))
      let posts = [];
      let replies=[];
      let lastKeyP = "";
      let lastKeyR = ""
      let countReplies = 1

      data.forEach((doc) => {
        let payload = doc.data()
        payload.id = doc.id
        posts.push(
            payload
            );
        lastKeyP = doc.data().timestamp;
        if(typeof doc.data().replies !== "number") {
        } else {
          countReplies = countReplies + doc.data().replies
        }
      })
      if (countReplies === 0) {
        countReplies = 1
      }
      const data2 = await getDocs(query(collection(db, "replies"), orderBy('timestamp', 'desc'), limit(countReplies)))
      data2.forEach((reply)=>{
          let payload = reply.data()
          payload.id = reply.id
          replies.push(payload)
          lastKeyR = reply.data().timestamp

    })
    return { posts, lastKeyP, lastKeyR, replies};
     
      
    } catch (e) {
      console.log(e);
    }
  },
  postsByProfileIdNextBatch: async (keyP, keyR, id, displayName) => {
    try {
      const data = await getDocs(query(collection(db, "posts"), where("uid", "==", id), orderBy("timestamp", "desc"), limit(10), startAfter(keyP)))
      let posts = [];
      let replies = [];
      let lastKeyP = "";
      let lastKeyR = ""
      let countReplies = 1

      data.forEach((doc) => {
        let payload = doc.data()
        payload.id = doc.id
        posts.push(
            payload
        );
        lastKeyP = doc.data().timestamp;
        if(typeof doc.data().replies !== "number") {
        } else {
          countReplies = countReplies + doc.data().replies
        }
      })
      if (countReplies === 0) {
        countReplies = 1
      }
        const data2 = await getDocs(query(collection(db, "replies"), orderBy('timestamp', 'desc'), limit(countReplies), startAfter(keyR)))
        data2.forEach((reply)=>{
            let payload = reply.data()
            payload.id = reply.id
            replies.push(payload)
            lastKeyR = reply.data().timestamp
            console.log(lastKeyR)

          })
          return { posts, lastKeyP, lastKeyR, replies};
      
    } catch (e) {
      console.log(e);
    }
  },
  /**
   * this function will be fired each time the user click on 'More Posts' button,
   * it receive key of last post in previous batch, then fetch next 5 posts
   * starting after last fetched post.  
   */

};

export default Posts