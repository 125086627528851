import React, {useState, useEffect} from 'react'
import Post from './Post'
import { useStateValue } from './StateProvider'
import { Button } from '@mui/material'
import './Replies.css'

function Replies({ replies }) {
    const [{ user, posted}, dispatch] = useStateValue()

    const [showing, setShowing] = useState(false)
    const handleShowing = () => {
        setShowing(!showing)
    }
    
    if (replies.length > 0) {
        replies.sort(function(a,b){return a.timestamp-b.timestamp})

        return (
            <>
            { showing ? (<>
                <Button className="buttonText" onClick={handleShowing}><span >Hide Replies</span></Button> 
                </>):(<>
                <Button className="buttonText" onClick={handleShowing}><span >Show Replies</span></Button>
                </>) }
            <div>
                {showing ? (<>
              {replies.map(post =>{
                  const isItLiked = () => {
                    if (typeof post.likedBy !== "undefined") {
                      let newArray = post?.likedBy?.filter((thing) => 
                        (
                          thing.uid === user.uid
                        ))
                        if (newArray.length > 0) {
                          return true
                        } else {
                          return false
                        }
                    }

                    }
                    const timestamp = () => {
                      if (post.timestamp instanceof Date) {
                        return `${post?.timestamp?.toLocaleTimeString()}  ${post?.timestamp?.toLocaleDateString()}`
                      } else {
                        return `${post?.timestamp?.toDate().toLocaleTimeString()}  ${post?.timestamp?.toDate().toLocaleDateString()}`
                      }
                    }

                  return (

                    
                      <Post
                          emoji={post.emoji}
                          isReply={post.isReply}
                          replyingToPost={post.replyingToPost}
                          replyingToUser={post.replyingToUser} 
                          likes={post.like}
                          likedByUser={isItLiked()}
                          uid={post.uid}
                          id={post.id}
                          key={post.id}
                          text={post.text}
                          avatar={post.avatar}
                          displayName={post.displayName}
                          userName={post.userName}
                          image={post.image}
                          badge={post.badge}
                          timestamp={timestamp()}
                      />
                  )
                  })}
                
                
                
                </>):(<></>)}
              </div>
              </>
        )
    }
    
    
}

export default Replies
