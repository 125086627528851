import React, { useContext } from 'react'
import { AuthContext } from './Auth'
import { useStateValue } from "./StateProvider"

import { Navigate, Outlet } from 'react-router-dom';



const PrivateRoute = ({ element: RouteComponent, ...rest}) => {
    const [{ user, dorkMode }, dispatch] = useStateValue()
    return (
        user !== null ? <Outlet /> : <Navigate to="/login" />
    )
}

export default PrivateRoute
