import React from 'react'
import { Link } from "react-router-dom"
import './Tag.css'

function Tag({label, className, editable}) {

  //we want to navigate to a page that filters the feed for this tag


  return (
    <Link to={`/Explore/${label}`} className={className}>
        {label}
    </Link>
  )
}

export default Tag
