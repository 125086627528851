import React, {useState } from 'react'
import './Notifications.css'
import Note from './Note.js'

import { useStateValue } from "./StateProvider";
import FeedHeader from './FeedHeader';
function Notfs() {
    const [{ user, profile },] = useStateValue()
    const [notes] = useState(profile?.notifications)

/**
 * this is blanking out the list every time we view
 * the idea was we'd "auto-read" notifications
 * instead: we want users to flag notifications as "read" manually
 * additionally: we should purge notifications periodically using Cloud Functions
 * 
 *     useEffect(()=>{
        const q = query(doc(db, `users/${user.uid}`))
        getDoc(q).then(()=>{
            updateDoc(q, {
                notifications: []
            })
        })
        },[posted])
 */            
  



    return (
        <div className='notifications'>

                <h6>Notifications</h6>
                <FeedHeader notes={notes?.length}/>
                <div className='notifications__tray'>
                    {notes?.length > 0? (
                    <>{notes?.map((note, index) =>{
                        return (<Note displayName={note.notification.displayName} uid={note.notification.uid} type={note.notification.type} title={note.notification.title} postId={note.notification.id} user={user} key={index} noteIndex={index} body={note.notification.body}/>)
                    })}</>
                    ):(<>
                    
                        <h6 className='subtitle2'>No notifications</h6>
                    </>)}
                </div>

        </div>
    )
}

export default Notfs
