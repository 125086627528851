import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from "firebase/auth";
import { getMessaging } from "firebase/messaging";
import { getDatabase } from "firebase/database";

const firebaseConfig = {

    apiKey: "AIzaSyAAvrw6TgKQwDl8CoHSm82N5e2Nz3xg8lw",
    authDomain: "sentimental-3e11a.firebaseapp.com",
    databaseURL: "https://sentimental-3e11a-default-rtdb.firebaseio.com",
    projectId: "sentimental-3e11a",
    storageBucket: "sentimental-3e11a.appspot.com",
    messagingSenderId: "51401150296",
    appId: "1:51401150296:web:c7f6db23a48bd08bb31f2d",
    measurementId: "G-55GXM1TP06"
  
  };
  

const firebaseApp = initializeApp(firebaseConfig);
export const db = getFirestore(firebaseApp);
export const auth = getAuth(firebaseApp);
export const database = getDatabase(firebaseApp);
export const messaging = getMessaging(firebaseApp);

  

export default db;

