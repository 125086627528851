import React, {useState, useEffect} from 'react'
import { doc, setDoc, arrayUnion, arrayRemove, } from "firebase/firestore"; 
import db from './firebase.js'
import { Avatar, Button } from '@mui/material';
import './ProfileChip.css'
import { LockOpen, NotInterested } from '@mui/icons-material';
import { useStateValue } from "./StateProvider"
import { actionTypes } from './reducer';
import { Link } from 'react-router-dom';

function ProfileChip({uid, name, email, avatar, emoji, blocked}, key) {

const [{ user, posted}, dispatch] = useStateValue()
const b = blocked
const [blockedState, setBlockedState] = useState(b)
useEffect(()=>{
  console.log('yip')
},[posted])
const handleBlock = () => {
  if (blockedState) {
    console.log(`unblocking ${uid}`)
    const myProfile = doc(db, `/users/${user.uid}`)
    const targetDoc = doc(db, `/users/${uid}`)
    const myPayload = {uid: user?.uid, displayName: user?.displayName}
    const theirPayload = {uid: uid, displayName: name}
    setDoc(targetDoc, {
        blockedBy: arrayRemove(myPayload),
    }, {merge: true})
    setDoc(myProfile, {
        blocking: arrayRemove(theirPayload),
    }, {merge: true})
    setBlockedState(false)
    dispatch(
        {
            type: actionTypes.SET_POSTED,
            posted: posted+1
        }
    )

  } else {
    console.log(`blockang ${uid}`)
    const myProfile = doc(db, `/users/${user.uid}`)
    const targetDoc = doc(db, `/users/${uid}`)
    const myPayload = {uid: user.uid, displayName: user.displayName}
    const theirPayload = {uid: uid, displayName: name}
    setDoc(targetDoc, {
        blockedBy: arrayUnion(myPayload),
    }, {merge: true})
    setDoc(myProfile, {
        blocking: arrayUnion(theirPayload),
    }, {merge: true})
    setBlockedState(true)
    dispatch(
        {
            type: actionTypes.SET_POSTED,
            posted: posted+1
        }
    )
  }

  }



  return (
    <div className="profileChip" key={key}>
      <div className='profileChip__section avatar'>
        <Avatar src = {avatar}/>
      </div>
      <div className='profileChip__section center '>
          
        <div className="profileChip__name" style={{flex:'1'}}>
          <span className='subtitle1'>
        <Link to={`/Profile/${uid}`}> 
            {name}
        </Link>
          </span> 

            <span className='captionText'>
              {emoji}
            </span>
        </div>
        
        <p className='captionText'>
          <Link
            to='#'
            onClick={(e)=>{
              window.location.href=`mailto:${email}`
              e.preventDefault()
            }}
          >
            {email}
          </Link>  
        </p>
      </div>
      <div className='profileChip__section blocked'>
        <span>{blockedState?(
          <div>
            <Button onClick={handleBlock} endIcon={<LockOpen />}>
            unblock
              
            </Button>
          </div>
        ):(
          <div>
            <Button onClick={handleBlock} endIcon={<NotInterested />}>
            block
              
            </Button>
          </div>
        )}</span>
      </div>
    </div>
  )
}

export default ProfileChip
