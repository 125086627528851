import React from 'react'

function CheatSheet() {
  return (
    <div>
      <table className="captionText" style={{marginTop: "12px", width: "100%"}}>
        <tr>
            <td style={{textDecoration: "underline", fontWeight: 500, color: "var(--brand-color"}}>
                <strong>mentioning@gmail.com</strong>
            </td>
            <td>
                @someone@gmail.com
            </td>
        </tr>
        <tr>
            <td>
                <strong>Bold</strong>
            </td>
            <td>
                **text**
            </td>
        </tr>
        <tr>
            <td>
                <em>Italic</em>
            </td>
            <td>
                *text*
            </td>
        </tr>
        <tr>
            <td style={{textDecoration: "line-through"}}>
                Strikethrough
            </td>
            <td>
               ~~text~~
            </td>
        </tr>
        <tr>
            <td style={{textDecoration: "underline", fontWeight: "500", color: "var(--brand-color)"}}>
                Link
            </td>
            <td>
                [title](http://www.example.com)
            </td>
        </tr>
        <tr>
            <td style={{fontFamily: "monospace"}}>
                Code
            </td>
            <td>
                `text`
            </td>
        </tr>
        <tr>
            <td>
                Image
            </td>
            <td>
                ![alt-text](http://www.example.com/img.jpg)
            </td>
        </tr>
        <tr>
            <td>
                <ul style={{margin: 0, paddingLeft: "12px"}}><li>List</li></ul>
            </td>
            <td>
                * text
            </td>
        </tr>
        <tr>
            <td>
                <blockquote style={{margin: 0, paddingLeft: "12px"}}>Blockquote</blockquote>
            </td>
            <td>
               &gt; text
            </td>
        </tr>
        <tr>
            <td>
                <strong>H1</strong>
            </td>
            <td>
                # text
            </td>
        </tr>
        <tr>
            <td>
            <strong>H2</strong>
            </td>
            <td>
                ## text
            </td>
        </tr>
        <tr>
            <td>
            <strong>H3</strong>
            </td>
            <td>
                ### text
            </td>
        </tr>
      </table>
    </div>
  )
}

export default CheatSheet
