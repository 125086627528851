import React from 'react'
import './SidebarOption.css'
import { useNavigate } from 'react-router-dom'
import { Button } from '@mui/material'
function SidebarOption({ active, text, Icon, id }) {

    const navigate = useNavigate()
    const selectOne = () => {
        if (id) {
            navigate(`/${id}`)
        } else {
            navigate(text)
        }
    }
    let option = ''
    if (active) {
        option = 'sidebarOption--active'
    } else {
        option = ''
    }

    return (
        <Button sx={{display: "flex", justifyContent: "flex-start", alignItems: "center"}} onClick={selectOne} className={`sidebarOption ` + option}>
            <Icon /> 
        </Button>
    )
}

export default SidebarOption
