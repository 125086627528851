import React, {useState} from 'react'
import './DescriptionForm.css'
import { useStateValue } from "./StateProvider"
import { TextField, Button, IconButton, Modal, Box } from '@mui/material';
import { doc,  updateDoc } from 'firebase/firestore'
import { db } from "./firebase"
import {Edit, Check, Cancel} from '@mui/icons-material/';
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
function DescriptionForm() {
    const [{ user, profile }] = useStateValue();
    const [description, setDescription] = useState('')
    const [editing, setEditing] = useState(false)

    const handleEditing = (option) => {
        if (option === false) {
            setEditing(!editing)
            return
        } else if (editing) {
            const myProfile = doc(db, `users/${user.uid}`)
            updateDoc(myProfile, {
              description: description
            })
            setEditing(!editing)    
        } else {
            setEditing(!editing)
        }
    }
    const modalStyle = {
        display: 'flex',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "80%",
        maxWidth: "600px",
        bgcolor: 'var(--surface)',
        boxShadow: 24,
        padding: "16px",
        paddingTop: "24px"
    }


    return (
        <div className="profile__headerDescription">
                    <span className="captionText">
                        {typeof profile?.description !== "undefined" ? (<ReactMarkdown children ={profile?.description} remarkPlugins={[remarkGfm]}/>
                        ):(
                            'A very happy young person looking forward to a bright and wonderful future')
                        }
                    </span>

                    
                    <Button className="descriptionButton"  onClick={handleEditing} endIcon={<Edit sx={{height: 12, width: 12, opacity: 0.5}} />}>
                    <span style={{color: "var(--text-main)", opacity: "0.5", textTransform: "uppercase"}}>edit description</span>
                    </Button>
                    
                    <Modal
                        open={editing}
                        onClose={handleEditing}
                        >
                        <Box sx={modalStyle} className="descriptionForm">
                            <TextField className="descriptionInput"
                                label="Description"
                                helperText="Visible on your profile" 
                                multiline 
                                onChange={(e)=>setDescription(e.target.value)} 
                                defaultValue={typeof description !== "undefined" ? (description):('A very happy young person looking forward to a bright and wonderful future')} 
                            />
                            <div className="descriptionButtons">
                                <IconButton type="submit" onClick={handleEditing}><Check /></IconButton>
                                <IconButton type="submit" onClick={()=>{handleEditing(false)}}><Cancel /></IconButton>
                            </div>
                        </Box>
                    </Modal>

        </div>

  )
}

export default DescriptionForm
