import React from 'react'
import './UserStatus.css'
import { Link } from 'react-router-dom';

function UserStatus({text, status, id}) {
    let online = false
    if (status === "online") {
        online = true
    } else {
        online = false
    }

  return (
    <div className="userStatus">
      {online? (<span style={{color: "var(--brand-color)"}}>●</span>):(<span style={{color: "var(--brand-background-active)"}}>●</span>)} 
      <Link to={`/Profile/${id}`} className="captionText statusLink">{text}</Link>
    </div>
  )
}

export default UserStatus