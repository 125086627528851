import React, {useState, useEffect} from "react";
import './Sidebar.css'
import SidebarOption from './SidebarOption.js'
import { MoreVert, Home, Search, Notifications, PermIdentity } from '@mui/icons-material'
import { useLocation } from 'react-router-dom';
import { useStateValue } from "./StateProvider";
import DorkMode from "./DorkMode.js"
import { auth } from "./firebase"
import { signOut } from "firebase/auth";
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { IconButton, Button } from "@mui/material";
import UserStatus from './UserStatus.js'
import {database ,db} from "./firebase";
import { getDocs, query, collection, where } from "firebase/firestore"
import { ref, set } from 'firebase/database'

import List from '@mui/material/List';



function Sidebar() {
    const [{ user, posted, profile }] = useStateValue()
    const [following, setFollowing] = useState([])
    let location = useLocation();

    useEffect(()=>{
        let payload = {uid: user.uid, displayName: user.displayName}
        getDocs(query(collection(db, "users"), where("followers", "array-contains", payload)))
            .then((data)=>{                    
                let array = []
                data.forEach((doc)=>{
                    let payload = doc.data()
                    payload.id = doc.id 
                    array.push(payload)                    
                    setFollowing(array)
                })
            })
    },[location, posted, profile])



    const logOut = () => {
        var uid = user.uid
        var userStatusDatabaseRef = ref(database, '/status/' + uid);
        var isOfflineForDatabase = {
            state: 'offline',
            email: user?.email,
            uid: uid,
            photoURL: user?.photoURL,
            displayName: user?.displayName,
            notificationsToken: []
          };
        set(userStatusDatabaseRef, isOfflineForDatabase)

        signOut(auth).then(()=>{
            console.log(`Bye! Buh-bye!`)
        }).catch(()=>{
            console.log(`sorry, you're stuck here`)
        })
    }
    const [state, setState] = React.useState({
          right: false,
        });
      
        const toggleDrawer = (open) => (event) => {
          if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
          ) {
            return;
          } 
      
          setState({ ...state, right: open });
        };

      
    return (

        <div className="sidebar" >
            <div className="sidebar__MoreVertButtonPanel">
            

            <IconButton className="brand__label" sx={{height: "48px", width: "48px"}}onClick={toggleDrawer(true) }> <MoreVert aria-label="menu" /> </IconButton>
            </div>
            
            
            <SwipeableDrawer
                anchor={'right'}
                open={state['right']}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
            >
                <div className="sidebar__drawer">      

                <h6 className="jointhaus brand__text">jointhaus</h6>

                {true ?(<></>):(<><SidebarOption active={(location.pathname === "/Home")} text="Home" Icon={Home} /> 
                    <SidebarOption active={(location.pathname === "/Explore")} text="Explore" Icon={Search} />
                    <SidebarOption active={(location.pathname === "/Notifications")} text="Notifications" Icon={Notifications} />
                    <SidebarOption active={(location.pathname === `/Profile/:target`)} text="Profile" id={`Profile/${user.uid}`} Icon={PermIdentity} /></>)}
                    <hr style={{width: "100%", color: `var(--brand-background)`}}/>
                    <Button fullWidth onClick={logOut} className="captionText">Logout</Button>
                    <DorkMode />
                    <hr style={{width: "100%", color: "var(--brand-background)"}}/>
                    <span className="overlineText" style={{textTransform:"uppercase", marginBottom: "8px"}}>Following</span>
                    <List
                        sx={{
                            width: "100%",
                            maxWidth: "250px",
                            maxHeight: "20vh",
                            overflow: 'auto',
                            display: "flex",
                            flexDirection: "column",
                            marginBottom: "12px"
                        }}
                    >
                        
                        {following.map((s)=>{
                        return (<UserStatus text={s.email} status={s.state} id={s.id}/>)
                        })}
                        

                    
                    </List>
                </div>

            </SwipeableDrawer> 
            
        </div >
    );
}

export default Sidebar;