

export const initialState = {
    user: null,
    profile: null,
    filterSetting: 'recent',
    dorkMode: false,
    following: [],
    posted: 0,
}

export const actionTypes = {
    SET_USER: "SET_USER",
    SET_USERPROFILE: "SET_USERPROFILE",
    SET_POSTED: "SET_POSTED",
}

const reducer = (state, action) => {
    switch (action.type) {
        case actionTypes.SET_USER:
            return {
                ...state,
                user: action.user,
                state: 'online',
            }
        case actionTypes.SET_USERPROFILE:
             return {
                ...state,
                profile: action.profile               
            }
        case actionTypes.SET_POSTED:
            return {
                ...state,
                posted: action.posted              
            }
        default:
            return state;
    }
}

export default reducer;