import React, { useState, forwardRef, useEffect } from 'react'
import './Post.css'
import { TextField, Button, IconButton, Avatar, Menu, MenuItem, Modal, Box } from '@mui/material'
import { MoreHoriz, Check, Forum, Reply, Favorite, FavoriteBorder, Edit, ForumOutlined } from '@mui/icons-material'
import Profile from "./Profile.js"
import db from './firebase'
import { doc, collection, setDoc, addDoc, getDoc, arrayUnion, arrayRemove, increment, deleteDoc } from "firebase/firestore"; 
import { useStateValue } from "./StateProvider"
import { actionTypes } from './reducer';
import PostForm from './PostForm'
import { useNavigate, useLocation, Link } from 'react-router-dom'
import Tag from './Tag.js'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';

const Post = forwardRef(({
    searchResult,
    boosts,
    boostedByUser,
    pkey,
    themes,
    repliesNum,
    emoji,
    replyingToUser,
    replyingToPost,
    isReply,
    likedByUser,
    displayName,
    id,
    uid,
    userName,
    badge,
    timestamp,
    text,
    image,
    imageAltText,
    avatar,
    likes
}, ref) => {


    
    const location = useLocation()
    const navigate = useNavigate()
    const [{ user, posted}, dispatch] = useStateValue()
    const [editingThemes, setEditingThemes] = useState(false)
    const [newThemes, setNewThemes] = useState(themes)
    const [liked, setLiked] = useState(likedByUser)
    const [likesState, setLikes] = useState(parseInt(likes))
    const [boosted, setBoosted] = useState(boostedByUser)
    const [boostsState, setBoosts] = useState(parseInt(boosts))
    const [replying, setReplying] = useState(false)
    const [reportModal, setReportModal] = useState(false)
    const [report, setReport] = useState('')
    const [editing, setEditing] = useState(false)


    useEffect(()=>{
        console.log(`refreshing`)
    },[posted, boostsState, likesState, replying])

    useEffect(()=>{
        if (themes?.length === 0) {
            setNewThemes("Nothemes")
        }
    },[editingThemes])


    const handleLike = () => {
        const whichSwitch = () => {
            if (isReply) {
                return 'replies'
            } else {
                return 'posts'
            }
        }
        const targetDoc = doc(db, `/${whichSwitch()}/${id}`)
        const origin = user.uid
        const originName = user.displayName
        const payload = {uid: origin, displayName: originName}
        const target = id

        getDoc(targetDoc).then((response)=>{
            let isItLiked = false
            const newArray = (response.data().likedBy?.filter((thing)=>(thing.uid === user.uid))) 
            if (newArray.length > 0){
                isItLiked = true
            }
          if (!isItLiked) {
            setDoc(targetDoc, {
                    like: increment(1),
                    likedBy: arrayUnion(payload)
                }, {merge:true})
                setLiked(true)
                if( typeof likesState !== 'number') {
                    setLikes(1)
                } else {
                    setLikes(likesState + 1)
                }

            } else {
            setDoc(targetDoc, {
                like: increment(-1),
                likedBy: arrayRemove(payload)
            }, {merge:true})
            setLiked(false)
            setLikes(likesState - 1)

            }
        });
        
    }
    const handleBoost = () => {
        console.log(`let's do this`)
        const whichSwitch = () => {
            if (isReply) {
                return 'replies'
            } else {
                return 'posts'
            }
        }
        const targetDoc = doc(db, `/${whichSwitch()}/${id}`)
        const origin = user.uid
        const originName = user.displayName
        const payload = {uid: origin, displayName: originName}
        const target = id
        getDoc(targetDoc).then((response)=>{
            let isItBoosted = false
            const newArray = (response.data().boostedBy?.filter((thing)=>(thing.uid === user.uid))) 
            if (newArray?.length > 0){
                isItBoosted = true
            }
            console.log(newArray, isItBoosted)    
          if (!isItBoosted) {
            console.log('boosts: ' + boosts)
            setDoc(targetDoc, {
                    boosts: increment(1),
                    boostedBy: arrayUnion(payload)
                }, {merge:true})
                setBoosted(true)
                if (typeof boostsState !== 'number') {
                    setBoosts(1)
                } else {
                    setBoosts(boostsState + 1)
                }
                console.log('boostsState: ')

            } else {
            setDoc(targetDoc, {
                boosts: increment(-1),
                boostedBy: arrayRemove(payload)
            }, {merge:true})
            setBoosted(false)
            setBoosts(boostsState-1)
            }
        });
        
    }

    

    const handleReply = () => {
        if (searchResult === true) {
            navigate(`/posts/${id}`)
        } else {
            setReplying(!replying)
        }
    }

    

    const handleClickNav = (clicked_id) => {
        console.log(`You clicked ${clicked_id}`)
        if (location.pathname !== `/posts/${clicked_id}`) {
            navigate(`/posts/${clicked_id}`)
        } else {
            console.log(`you're already here dummy`)
        }
    }
    const handleClickClipboard = (popup, clicked_id) => {
        console.log(`You clicked ${clicked_id}`);
        let copyText = `${window.location.origin}/posts/${clicked_id}`;
        console.log(copyText);
        navigator.clipboard
            .writeText(copyText)
        popup.close()    
    }
    
    
    const handleEditThemes = () => {
        setEditingThemes(!editingThemes)
        console.log("tryna add a theme m8")
    }

    const sendNewThemes = () => {
        
        setEditingThemes(false)
        const targetDoc = doc(db, `/posts/${id}`)
        setDoc(targetDoc, {
                themes: newThemes?.toString().replace(/\s/g, "").split(",")
        }, {merge: true})
        dispatch(
            {
                type: actionTypes.SET_POSTED,
                posted: posted+1
            }
        )
        }
        
    const handleClickBlock = () => {
        console.log(`blockang ${uid}`)
        const myProfile = doc(db, `/users/${user.uid}`)
        const targetDoc = doc(db, `/users/${uid.uid}`)
        const myPayload = {uid: user.uid, displayName: user.displayName}
        const theirPayload = {uid: uid.uid, displayName: displayName }
        setDoc(targetDoc, {
            blockedBy: arrayUnion(myPayload),
        }, {merge: true})
        setDoc(myProfile, {
            blocking: arrayUnion(theirPayload),
        }, {merge: true})
        dispatch(
            {
                type: actionTypes.SET_POSTED,
                posted: posted+1
            }
        )
    }

    const handleClickDelete = (id) => {
        const whichSwitch = () => {
            if (isReply) {
                return 'replies'
            } else {
                return 'posts'
            }
        }
        const targetDoc = doc(db, `/${whichSwitch()}/${id}`)
        deleteDoc(targetDoc)
        dispatch(
            {
                type: actionTypes.SET_POSTED,
                posted: posted+1
            }
        )
        

    }
    const handleClickReport = ()=> {
        setReportModal(!reportModal)
    }

    const cancelReport = () => {
        console.log('ope, nevermind')
        setReport('')
        setReportModal(false)
    }
    const sendReport = ()=>{
        console.log(report)
        addDoc(collection(db, 'reports'), {
            reporter: user.uid,
            report: report,
            reportedUser: uid,
        })
        dispatch(
            {
                type: actionTypes.SET_POSTED,
                posted: posted+1
            }
        )
        setReportModal(false)
    }
    const reportModalStyle = {
        backgroundColor: "var(--surface)",
        flex: 1,
        maxWidth: "400px",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        padding: "36px"
    }

    const reportModalContainerStyle = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    }

    const handleEdit = () => {
        if (searchResult === true) {
            navigate(`/posts/${id}`)
        } else {
            setEditing(!editing)
        }
    }

    const handleClickEdit = () => {
        setEditing(!editing)
        dispatch(
            {
                type: actionTypes.SET_POSTED,
                posted: posted+1
            }
        )
    }
    

    return (
        <>
        <div className='post' ref={ref} key={pkey}  id={id}>
            <div className={`post__body ${isReply ? ('isReply'):('')}`}>
                <div className='post__textContainer'>
                    <div className='post__textSection1'>
                        <div className="post__textCol1">
                            <Avatar src={`${avatar}`} alt={displayName} referrerPolicy="no-referrer" sx={{height: 24, width: 24}}/>
                            <Link to={`/Profile/${uid?.uid}`}>
                            <span className='subtitle1'>{displayName}</span></Link>
                            <span className='captionText'>{emoji}</span>

                        </div>
                        
                        <div className='post__textCol2'>
                            
                            <span className='captionText'>{timestamp}</span>
                        </div>

                        <div className='post__textCol3'>
                            
                                        
                        </div>

                    </div>
                    <div className='post__textSection2'>

                        <div style={{ whiteSpace: "pre-line" }} className="body2">
                            <ReactMarkdown children={text?.replace(/@(\S*)/g,'[$1](/Profile/@$1)')} remarkPlugins={[remarkGfm]} />
                        </div>

                        {isReply? ([]):(<>{!editingThemes ?(<div className="captionText post__textSection2themes">{themes?.map(theme => {
                                    if (theme === "Nothemes") {
                                        return ([])
                                    } else {
                                        return (<Tag className="postTheme" editable={user.uid === uid?.uid ? (true):(false)} label={theme} />)
                                    }
                                })} 
                            {user.uid === uid?.uid ? (<><IconButton><Edit onClick={()=>{handleEditThemes()}} sx={{height: "12px", width: "12px"}} /></IconButton><span className="captionText" style={{color: "var(--text-muted)"}}>{themes.length > 1 ? (<></>):(`add themes`)}</span></>):([])}</div>
                            ):(<div className="captionText post__textSection2themes">
                                    
                                    <TextField
                                        fullWidth 
                                        onChange={(e)=> setNewThemes(e.target.value)}
                                        placeholder={themes} 
                                        defaultValue={themes.join(', ')}
                                        value={newThemes}
                                        type="text"
                                        className="themes__input"                                                             
                                        />
                                    <IconButton onClick={()=> {sendNewThemes()}}><Check /></IconButton>
                                </div>)}</>
                            )}
                    </div>
                </div>

                <img src={image} alt={imageAltText} />


                <div className="post__footer"> 
                    
                        <div className="post__footerReplies">
                            
                            <div className='post__replyButton'>
                                <p className='body2'>{repliesNum}</p>
                                
                                <IconButton aria-label="comment" onClick={handleReply}>
                                    <Reply sx={{height: 16, width: 16}} />
                                </IconButton>
                            </div>
                        </div>
                        
                        {isReply? ([]):(
                        <div className='post__footerBoost'>
                            <div>
                                <p className="body2"> {typeof boosts === "undefined" ? ('undefined') : boostsState > 0 ? (`${boostsState}`):(<>{'0'}</>)} </p>
                            </div>
                        <div>
                        
                        <IconButton onClick={handleBoost}>
                            {boosted? (<Forum sx={{height: 16, width: 16}} />):(<ForumOutlined sx={{height: 16, width: 16}} />)}
                                
                            </IconButton>
                        </div>
                        </div>)}
                    <div className="post__footerLikes">
                        <div> <p className='body2'> {typeof likes === "undefined" ? ('undefined') : likesState > 0 ? (`${likesState}`):(<>{'0'}</>)} </p> </div>
                        <div>
                            {liked? (
                                <IconButton aria-label="liked" onClick={handleLike}>
                                    <Favorite sx={{height: 16, width: 16}}  fontSize="small" />
                                </IconButton>):(
                                <IconButton aria-label="like"  onClick={handleLike}>
                                    <FavoriteBorder sx={{height: 16, width: 16}} fontSize="small" />
                                </IconButton>
                            )}
                        </div>
                    </div>
                    <div className="post__footerMore">
                    <PopupState variant="popover" popupId="demo-popup-menu">
                        {(popupState) => (
                            <React.Fragment>
                                <IconButton {...bindTrigger(popupState)} sx={{cursor:"pointer"}} aria-label="post options">
                                    <MoreHoriz  />
                                    </IconButton>                                        
                                <Menu {...bindMenu(popupState)} className="post__contextMenu">
                                    
                                    {isReply?([]):(<div>
                                    
                                    <MenuItem onClick={()=>{handleClickClipboard(popupState, id)}}><span className="hoverme" >copy link to post</span></MenuItem>
                                    <MenuItem component={Link} to={`/posts/${id}`} onClick={()=>{handleClickNav(id)}}><span className="hoverme" >view post</span></MenuItem>                                    
                                    
                                    </div>)}
                                    
                                    { user?.uid === uid?.uid ? (
                                        <div>
                                            <MenuItem onClick={()=>{handleClickEdit(id)}}>
                                                <span className="hoverme">
                                                    edit post
                                                </span>
                                            </MenuItem>
                                            <MenuItem onClick={()=>{handleClickDelete(id)}}>
                                                <span className="hoverme">
                                                    delete post
                                                </span>
                                            </MenuItem>
                                        </div>
                                    
                                    
                                    ):(
                                        <div>
                                        <MenuItem onClick={()=>{handleClickReport(uid, displayName)}}>
                                            <span className="hoverme">
                                                report user
                                            </span>
                                        </MenuItem>
                                        <MenuItem onClick={()=>{handleClickBlock(uid, displayName)}}>
                                            <span className="hoverme" >
                                                block user
                                            </span>
                                        </MenuItem>
                                        <Modal sx={reportModalContainerStyle}
                                            open={reportModal}
                                            onClose={handleClickReport}>
                                                <Box sx={reportModalStyle}>
                                                    <h5 style={{marginTop: "0px", marginBottom: "16px"}}>Report User</h5>
                                                    <p className='body2'>
                                                        We take community safety seriously. Feel threatened or see danger? Please let us know!
                                                    </p>
                                                    <form>

                                                        <TextField 
                                                            onChange={(e)=> setReport(e.target.value)}
                                                            value={report}
                                                            fullWidth
                                                            multiline 
                                                            variant="outlined"
                                                            placeholder="What's going on? Please include any context and relevant details"
                                                            type="text"
                                                            >

                                                        </TextField>
                                                    <p className='captionText'>
                                                    <em>You are reporting <strong>{displayName} - {userName}</strong></em>
                                                    </p>
                                                        <Button onClick={sendReport}>Submit</Button>
                                                        <Button onClick={cancelReport}>Cancel</Button>
                                                    </form>
                                                </Box>
                                        </Modal>
                                        
                                        </div>)
                                    }

                                </Menu>
                                </React.Fragment>

                        )}
                    </PopupState>
                    </div>
                </div>
            </div>

            
        </div>
        {replying? (<>
            <PostForm 
                replying 
                replyingToUser={uid}
                replyingToUserName={userName} 
                replyingToPost={isReply? (replyingToPost):(id)} 
                onSubmit={handleReply}
                />
            
            </>):(
            [])}
        {editing? (<>
        <PostForm 
            editing
            isReply={isReply}
            id={id} 
            replyingToUser={uid}
            replyingToUserName={userName} 
            replyingToPost={isReply? (replyingToPost):(id)} 
            onSubmit={handleEdit}
            />
        
        </>):(
        [])}
        
    </>
    )
})

export default Post
