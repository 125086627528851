import React, { useEffect } from 'react'
import "./Login.css"
import { auth } from "./firebase"
import { signInWithPopup, setPersistence, GoogleAuthProvider } from "firebase/auth";
import { useStateValue } from "./StateProvider"
import { actionTypes } from "./reducer"
import { useNavigate } from 'react-router-dom';
import { Button, IconButton } from '@mui/material';
import { MailOutline } from '@mui/icons-material/';
import Help from './Help.js'

function Login() {

    const [{ user, profile }] = useStateValue()
    const [state, dispatch] = useStateValue()
    const navigate = useNavigate()
    
    useEffect(()=>{
        if (user !== null) {
            requestPermission()
            navigate('/Home')
        }
    },[user])
    
    
    const requestPermission = () => {
        console.log('Requesting Permission...')
        Notification.requestPermission().then((permission) =>{
            if (permission === 'granted') {
                console.log('Notifications permission granted')
            }
        })
      
      }
      

    const signIn = () => {
        setPersistence(auth)
        const provider = new GoogleAuthProvider()
        provider.addScope('profile')
        provider.addScope('email')
        signInWithPopup(auth, provider)
            .then((result) => {
                    dispatch({
                        type: actionTypes.SET_USER,
                        user: result.user,
                    })
                    
            })
            .catch((error) => {
                console.log(error.code)
                console.log(error.message)
        
        }) 
    }




    return (
        <div className='login' id="container">
                <div id="content" className="login__container">
                <div className="login__oAuthPane">
                    <h1 className='brandText jointhaus'>jointhaus</h1>
                    <p className="captionText">
                        Pre-Alpha Build
                    </p>
                    <Button onClick={signIn} variant="contained" className="brand__button">Sign In With Google</Button>
                </div>
                <div>
                    <IconButton
                        to='#'
                        onClick={(e)=>{
                            window.location.href = `mailto:adamjhuston@gmail.com`;
                            e.preventDefault() 
                    }}>
                        <MailOutline />
                    </IconButton>

                    <Help />
                    
                </div>
            </div>
            
        </div>
    )
}

export default Login
