import React, { useState } from 'react'
import { IconButton, Modal, Box } from '@mui/material';
import { QuestionMark, Close } from '@mui/icons-material/';
function Help() {
    const [que, setQue] = useState(false)

    const handleQue =()=>{
        setQue(!que)
    }


        
  
    const style ={
        backgroundColor: "var(--surface)",
        flex: "0.8",
        marginLeft: "auto",
        marginRight: "auto",
        textAlign: "center",
        padding: "32px",
        width: "70vw",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        overflowY: "scroll",
        overflowX: "hidden"
    }


  return (
    <>
      <IconButton onClick={handleQue}>
                        <QuestionMark />
                    </IconButton>
                    <Modal
                        open={que}
                        onClose={handleQue}
                        sx={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}
                        >
                        <Box sx={style}>
                            <div className='login__modalTextContainer'>
                                <IconButton  className="modalClose" onClick={()=>handleQue(false)}>
                                    <Close/>
                                </IconButton>                    
                                <h5 style={{margin: "8px"}}>FAQ</h5>
                                <div style={{textAlign: "left"}}>
                                    <h6 style={{padding: 0}}>What am I looking at?</h6>
                                    <p className="body2">
                                        <strong>jointhaus</strong> is a lightweight social media app. It's clean, easy to use, and it doesn't exploit you.
                                        <p>
                                            This is a Pre-Alpha build: it's publicly released for testing and promotion. It's free right now but future versions may require a license purchase.
                                        </p>
                                    </p>
                                    <h6  style={{padding: 0}}>Uhh, great. Why? </h6>
                                    <p className="body2">
                                        We look at it the other way: why use one of the big ones? They relentlessly push ads and outrage because they're incentivized to.
                                        <p>
                                            <strong>jointhaus</strong> is different: we don't sell your data or serve ads. We design around user empowerment and system transparency. 
                                        </p>
                                        <p>
                                            When you post, you'll see your posts may automatically be tagged with "themes". Just like on other social networks, <strong>jointhaus</strong> performs sentiment and content analysis on your posts. Unlike other networks, you're in charge: by editing your post's "themes" you make your posts more discoverable and control how the system understands the content of your posts.
                                        </p>
                                        <p>
                                            Development will be funded by lifetime membership licenses, so what you want matters: you're in the driver's seat. 
                                        </p>
                                    </p>
                                    <h6 style={{padding: 0}}>Does that mean the feed is algorithmically generated?</h6>
                                    <p className="body2">
                                        Not yet! We're building that feature, but it will always be optional and customizable. 
                                    </p>
                                    <h6  style={{padding: 0}}>Where are the DMs?</h6>
                                    <p className="body2">
                                        In your e-mail inbox, where you can save them forever or flag them as spam.
                                        <p>
                                            You might be an avid social media user and have experienced this before: direct messages fuel drama!
                                        </p>
                                        <p>
                                            We don't need the drama, the data, or the responsibility of hosting your private conversations, so we don't plan to build in-app direct messages just yet.
                                        </p>
                                        <p>
                                            <strong>jointhaus</strong> opens e-mail addresses in your native e-mail client, where you have the direct messaging experience of your choice. 
                                        </p>
                                    </p>
                                    <h6  style={{padding: 0}}>Where are the Privacy Settings?</h6>
                                    <p className="body2">
                                        Coming soon - it's on the road map! In pre-launch, there's an element of privacy inherent to this being a very small network.
                                        </p><p className="body2">
                                        We understand that might not be enough, so currently you can block individual users from seeing your posts: this will also block you from seeing their posts.
                                        Future releases will include follower-only post and profile privacy settings.
                                    </p>
                                    <h6  style={{padding: 0}}>Wow, the search is really thorough. Is that safe?</h6>
                                    <p className="body2">
                                        We think so! It doesn't search user profiles, just posts. Search will exclude follower-only posts and profiles, when the functionality exists.
                                        <p>
                                            Additionally, users must log in to the app to search or view any profiles and posts. If you experience harrassment from a user, please report them.
                                        </p>
                                    </p>
                                    <h6 style={{padding: 0}}>I have a suggestion!</h6>
                                    <p className="body2">
                                        Awesome! Write a post and add <em>JointFeatureRequest</em> to the themes! Don't want it public? Send an <a href="mailto:adamjhuston@gmail.com">e-mail!</a>
                                    </p>
                                    <h6 style={{padding: 0}}>This is more of a bug, actually.</h6>
                                    <p className="body2">
                                        Awesome! Write a post and add <em>JointBugReport</em> to the themes! If the bug is you can't post: send an <a href="mailto:adamjhuston@gmail.com">e-mail!</a>
                                    </p>
                                    <h6 style={{padding: 0}}>Are you going to continue developing this?</h6>
                                    <p className="body2">
                                        Definitely. It costs money to run these services at scale: I'll have to sell memberships or take donations eventually! The Pre-Alpha version of this service is what we in the biz call a "Minimum Viable Product", for testing and evaluation. Interested in learning more? Wondering if you should invest? Send an <a href="mailto:adamjhuston@gmail.com">e-mail!</a>
                                    </p>
                                    <h6 style={{padding: 0}}>Can I offer a tip or a donation, to help?</h6>
                                    <p className="body2">
                                         <a href="https://paypal.me/adamjameshuston">Please and thank you!</a>
                                    </p>

                                </div>
                            </div>
                        </Box>
                    </Modal>
    </>
  )
}

export default Help
