import React, {useState} from 'react'
import './Emoji.css'
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useStateValue } from "./StateProvider"
import { updateDoc, doc, } from "firebase/firestore";
import db from './firebase'
import emojiJSON from './emoji.json'

function Emoji() {
    const [{ user, profile }] = useStateValue()

    const [picking, setPicking] = useState(false)

    const handlePicker = () => {
        setPicking(!picking)
    }
    const target = user.uid

    const handleChange = (event) => {
        let targetUserDoc = doc(db, `/users/${target}`)
        updateDoc(targetUserDoc, {emoji: event.target.value,})
    }

    

  return (
    <span className="postForm__emojiPicker">
        {picking? (
            <span className="emojiPicker__picking" onClick={handlePicker}>
                <Select
                    labelId="emoji-picker"
                    id="emoji-picker"
                    value={profile?.emoji}
                    label="Emoji"
                    onChange={handleChange}
                >
                    {emojiJSON.map(item=>(
                        <MenuItem value={`${item.emoji}`} alt={`${item.aliases[0]}`}>{item.emoji}</MenuItem>
                    ))}
                
 
                </Select>
            </span>):(
            <span className="emojiPicker__picked" onClick={handlePicker}>
                {profile?.emoji}
            </span>)
            }
    </span>
  )
}

export default Emoji