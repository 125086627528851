import React from 'react'
import FeedHeader from './FeedHeader'
import './Search.css'
import algoliasearch from 'algoliasearch/lite'
import {
    InstantSearch,
    SearchBox,
    Hits,
    Configure,
    Pagination
  } from 'react-instantsearch-hooks-web';
import Post from './Post'

  const { REACT_APP_ALOGLIA_APP_ID, REACT_APP_ALGOLIA_SEARCH_API_KEY } = process.env


  function Search() {
    const searchClient = algoliasearch(
      REACT_APP_ALOGLIA_APP_ID, REACT_APP_ALGOLIA_SEARCH_API_KEY
      );
      


  function Hit ({ hit }) {
    
    const timestring = new Date(hit.timestamp)
    return (


      <Post 
        boostedByUser={false}
        boosts={hit.boosts}
        themes={hit.themes}
        repliesNum={hit.replies}
        emoji={hit.emoji}
        likes={hit.like}
        likedByUser={false}
        uid={hit.uid}
        id={hit.objectID}
        pkey={hit.id}
        text={hit.text}
        avatar={hit.avatar}
        displayName={hit.displayName}
        userName={hit.userName}
        timestamp={`${timestring.toLocaleTimeString()}  ${timestring.toLocaleDateString()}`}
        searchResult
      />
      

    );
    }

/**paginate hits
 * by default there should be no hits
 */
  return (
    <div className="search" id="search">
        <FeedHeader />
        <InstantSearch 
          searchClient={searchClient} 
          indexName='posts'
          initialUiState={{
            indexName: {
              page: 0,
              hitsPerPage: 5
            },
          }}
          >

            <SearchBox autoFocus placeholder='Search...' defaultValue=' ' className='searchbox'/>
            <Configure 
              hitsPerPage='3'
              query='Happy Stuff'

            />
            <Hits hitComponent={Hit} className="hits"/>
            <Pagination 
              container="#search"

            />
          

        </InstantSearch>


    </div>
  )
}

export default Search
