import React, {useState, useEffect} from 'react'
import ProfileChip from './ProfileChip.js'
import { collection, getDocs, query, where } from "firebase/firestore"; 
import db from './firebase.js'
import './ChipList.css'


function Followers({blocked, chipList}) {

  const [list, setList] = useState([])
  const [chips, setChips] = useState([])
  
  
  let chipListCopy = []
  if (typeof chipList === "undefined") {
    chipListCopy = []
    } else {
    chipListCopy = chipList
  }
  useEffect(()=>{
    let holdem = []
    if (typeof chipListCopy !== "undefined") {

      for (let i = 0; i < chipListCopy.length; i++){
        console.log(chipListCopy[i].uid)
        holdem.push(chipListCopy[i].uid)
      }
      console.log('holdem: ' + holdem)
      setChips(holdem)

    }
  },[chipList])
  
  
  
  
  useEffect(()=>{
      let results = []
      
      if(chips.length > 0) {
        
              getDocs(query(collection(db, "users"), where("uid", "in", chips))).then((docs)=>{
                docs.forEach((doc)=>{
                  console.log(doc.data())
                  let payload = doc.data()
                  payload.id = doc.id
                  results.push(payload)
                })    
                console.log(results) 
                setList(results)
              })

      } else {
        setList([])
      }


  },[chips, chipList])
  



  return (<div className="chipList">
    {list?.map(follower=>{
        return(<ProfileChip
          blocked={blocked}
          uid = {follower.uid}
          key = {follower.uid}
          name = {follower.displayName}
          email = {follower.email}
          avatar = {follower.avatar}
          emoji = {follower.emoji}
        />)
    })}
  </div>
  )
}

export default Followers
