import React, { useState, useEffect } from 'react'
import './Feed.css'
import Post from './Post.js'
import FlipMove from 'react-flip-move'
import { useStateValue } from "./StateProvider"
import db from './firebase'
import { doc, updateDoc } from 'firebase/firestore'
import { useLocation } from 'react-router-dom'
import { ToggleButton, ToggleButtonGroup, Button } from '@mui/material'
import { useParams } from 'react-router-dom'
import Replies from './Replies.js'
import Posts from './Posts'
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import FeedHeader from './FeedHeader'


function Feed({id, displayName}) {
    const [filter, setFilter] = useState('recent')
    const location = useLocation()
    const [{ user, profile, posted }] = useStateValue();
    const [posts, setPosts] = useState([])
    const [replies, setReplies] = useState([])
    const tag = useParams();
    const [lastKeyP, setLastKeyP] = useState("");
    const [lastKeyR, setLastKeyR] = useState("");
    const [nextPosts_loading, setNextPostsLoading] = useState(false);

 
    useEffect(() => {
      const isItInTheBlockList = (post) => {
          let list = profile?.blocking?.filter(person => (person.uid === post.uid))
  
            if (list?.length > 0) {
              return true
            } else { return false }
      }
      const isItInTheBlockerList = (post) => {
        let list = profile?.blockedBy?.filter(person => (person.uid === post.uid))
        
          if (list?.length > 0) {
            return true
          } else { return false}
      }
      

        if (location.pathname.includes(`/Profile/`)) {
            console.log(displayName)
            const idPayload = {uid: id, displayName: displayName}
            console.log(idPayload)
            Posts.postsByProfileIdFirstBatch(idPayload)
            .then((res) => {
                setPosts(res.posts.filter(post => !(isItInTheBlockList(post.uid) ) ).filter(post => !(isItInTheBlockerList(post.uid))));
                setReplies(res.replies.filter(post => !(isItInTheBlockList(post.uid) ) ).filter(post => !(isItInTheBlockerList(post.uid))))
                setLastKeyP(res.lastKeyP);
                setLastKeyR(res.lastKeyR)
              }) 
              .catch((err) => {
                console.log(err);
              });
        } else if (location.pathname.includes(`/posts/`)) {
            Posts.postsByIdFirstBatch(tag.postId)
            .then((res) => {
              setPosts(res.posts.filter(post => !(isItInTheBlockList(post.uid) ) ).filter(post => !(isItInTheBlockerList(post.uid))));
              setReplies(res.replies.filter(post => !(isItInTheBlockList(post.uid) ) ).filter(post => !(isItInTheBlockerList(post.uid))))
              setLastKeyP(res.lastKeyP);    
              setLastKeyR(res.lastKeyR)
            }) 
            .catch((err) => {
              console.log(err);
            });
            console.log('replies: ', replies)
        } else if (location.pathname.includes(`/Explore/`)) {
            Posts.postsByTagFirstBatch(tag.topic)
            .then((res) => {
                setPosts(res.posts.filter(post => !(isItInTheBlockList(post.uid) ) ).filter(post => !(isItInTheBlockerList(post.uid))));
                setReplies(res.replies.filter(post => !(isItInTheBlockList(post.uid) ) ).filter(post => !(isItInTheBlockerList(post.uid))))
                setLastKeyP(res.lastKeyP);
                setLastKeyR(res.lastKeyR)
    
              }) 
              .catch((err) => {
                console.log(err);
              });
        } else if (profile?.feedFilter === "following") {
            Posts.postsFollowingFirstBatch(profile.following, user.uid)
            .then((res) => {
              setPosts(res.posts.filter(post => !(isItInTheBlockList(post.uid) ) ).filter(post => !(isItInTheBlockerList(post.uid))));
              setReplies(res.replies.filter(post => !(isItInTheBlockList(post.uid) ) ).filter(post => !(isItInTheBlockerList(post.uid))))
              setLastKeyP(res.lastKeyP);
              setLastKeyR(res.lastKeyR)
  
            })
            .catch((err) => {
              console.log(err);
            });
        } else if (profile?.feedFilter === "recent") {
            Posts.postsFirstBatch()
            .then((res) => {
                setPosts(res.posts.filter(post => !(isItInTheBlockList(post.uid))).filter(post => !(isItInTheBlockerList(post.uid))));
                setReplies(res.replies.filter(post => !(isItInTheBlockList(post.uid)) ).filter(post => !(isItInTheBlockerList(post.uid))))
                setLastKeyP(res.lastKeyP);
                setLastKeyR(res.lastKeyR)
    
              })
              .catch((err) => {
                console.log(err);
              });
        } else if (profile?.feedFilter === "shared") {
          console.log(profile.following)
          Posts.postsBoostedFirstBatch(profile.following)
          .then((res) => {
                console.log(res)
              setPosts(res.posts.filter(post => !(isItInTheBlockList(post.uid) ) ).filter(post => !(isItInTheBlockerList(post.uid))));
              setReplies(res.replies.filter(post => !(isItInTheBlockList(post.uid) ) ).filter(post => !(isItInTheBlockerList(post.uid))))
              setLastKeyP(res.lastKeyP);
              setLastKeyR(res.lastKeyR)
  
            })
            .catch((err) => {
              console.log(err);
            });
      }
    }, [user, profile, location, filter, displayName]);

    const fetchMorePosts = () => {
      const isItInTheBlockList = (post) => {
        let list = profile?.blocking?.filter(person => (person.uid === post.uid))

          if (list.length > 0) {
            return true
          } else { return false }
      }
      const isItInTheBlockerList = (post) => {
        let list = profile?.blockedBy?.filter(person => (person.uid === post.uid))
        
          if (list.length > 0) {
            return true
          } else { return false}
      }

      if (typeof lastKeyP ===  "object") {
        setNextPostsLoading(true);
          if(location.pathname.includes(`/Profile/`)) {
            const idPayload = {uid: id, displayName: displayName}

              Posts.postsByProfileIdNextBatch(lastKeyP, lastKeyR, idPayload)
              .then((res) => {
                  setLastKeyP(res.lastKeyP)
                  setLastKeyR(res.lastKeyR);
                  setPosts(posts.concat(res.posts).filter(post => !(isItInTheBlockList(post.uid) ) ).filter(post => !(isItInTheBlockerList(post.uid))));
                  setReplies(replies.concat(res.replies).filter(post => !(isItInTheBlockList(post.uid) ) ).filter(post => !(isItInTheBlockerList(post.uid))))
                  console.log(replies)
                  setNextPostsLoading(false);
              })
              .catch((err) => {
                  console.log(err);
                  setNextPostsLoading(false);
              });
          } else if(location.pathname.includes(`/posts/`)) {
              Posts.postsByIdNextBatch(lastKeyP, lastKeyR, tag.postId)
              .then((res) => {
                setLastKeyP(res.lastKeyP)
                setLastKeyR(res.lastKeyR);
                setPosts(posts.concat(res.posts).filter(post => !(isItInTheBlockList(post.uid) ) ).filter(post => !(isItInTheBlockerList(post.uid))));
                setReplies(replies.concat(res.replies).filter(post => !(isItInTheBlockList(post.uid) ) ).filter(post => !(isItInTheBlockerList(post.uid))))
                setNextPostsLoading(false);
              })
              .catch((err) => {
                console.log(err);
                setNextPostsLoading(false);
              });
          }
          else if(location.pathname.includes(`/Explore/`)) {
              Posts.postsByTagNextBatch(lastKeyP, lastKeyR, tag.topic)
              .then((res) => {
                setLastKeyP(res.lastKeyP)
                setLastKeyR(res.lastKeyR);
                setPosts(posts.concat(res.posts.filter(post => !(isItInTheBlockList(post.uid) ) ).filter(post => !(isItInTheBlockerList(post.uid)))));
                setReplies(replies.concat(res.replies.filter(post => !(isItInTheBlockList(post.uid) ) ).filter(post => !(isItInTheBlockerList(post.uid)))))
                console.log(replies)
                setNextPostsLoading(false);
              })
              .catch((err) => {
                console.log(err);
                setNextPostsLoading(false);
              });
          } else if (profile?.feedFilter === "following") {
              console.log('fetching following')
              Posts.postsFollowingNextBatch(lastKeyP, lastKeyR, profile.following, user.uid)
                .then((res) => {
                  setLastKeyP(res.lastKeyP)
                  setLastKeyR(res.lastKeyR);
                  setPosts(posts.concat(res.posts.filter(post => !(isItInTheBlockList(post.uid) ) ).filter(post => !(isItInTheBlockerList(post.uid)))));
                  setReplies(replies.concat(res.replies.filter(post => !(isItInTheBlockList(post.uid) ) ).filter(post => !(isItInTheBlockerList(post.uid)))))
                  console.log(replies)
                  setNextPostsLoading(false);
                })
                .catch((err) => {
                  console.log(err);
                  setNextPostsLoading(false);
                });

          } else if (profile?.feedFilter === "recent") {
              console.log('fetching recent')                                                                                         
              Posts.postsNextBatch(lastKeyP, lastKeyR)
                .then((res) => {
                  const isItInTheBlockList = (post) => {
                    let list = profile?.blocking.filter(person => (person.uid === post))
                    if (list?.length > 0) {
                      return true
                    } else { return false }
                  }
                  const isItInTheBlockerList = (post) => {
                    let list = profile?.blockedBy.filter(person => (person.uid === post))
                    if (list?.length > 0) {
                      return true
                    } else { return false}
                  }
                  setLastKeyP(res.lastKeyP)
                  setLastKeyR(res.lastKeyR);
                  setPosts(posts.concat(res.posts.filter(post => !isItInTheBlockList(post.uid) ).filter(post => !isItInTheBlockerList(post.uid))));
                  setReplies(replies.concat(res.replies.filter(post => !isItInTheBlockList(post.uid) ).filter(post => !isItInTheBlockerList(post.uid))))
                  setNextPostsLoading(false);
                })
                .catch((err) => {
                  console.log(err);
                  setNextPostsLoading(false);
                });
          } else if (profile?.feedFilter === "shared") {
            console.log('fetching shared')
            console.log(profile.following)
            Posts.postsBoostedNextBatch(lastKeyP, lastKeyR, profile?.following)
              .then((res) => {
                setLastKeyP(res.lastKeyP)
                setLastKeyR(res.lastKeyR);
                setPosts(posts.concat(res.posts.filter(post => !isItInTheBlockList(post.uid)).filter(post => !(isItInTheBlockerList(post.uid))) ));
                setReplies(replies.concat(res.replies.filter(post => !(isItInTheBlockList(post.uid))).filter(post => !(isItInTheBlockerList(post.uid))) ))
                setNextPostsLoading(false);
              })
              .catch((err) => {
                console.log(err);
                setNextPostsLoading(false);
              });
          }
      } 
    };
    

    const handleChange = (event, newFilter) => {
        
      if (newFilter !== null) {
        console.log(newFilter)
        setFilter(newFilter)
        const myProfile = doc(db, `users/${user.uid}`)
        updateDoc(myProfile, {
          feedFilter: newFilter
        })
      }
    };

    useBottomScrollListener(fetchMorePosts, {debounce: 0, offset: 50, triggerOnNoScroll: false});
    useEffect(()=>{
      console.log(posted)
      console.log('we pushing')
      console.log(replies)
      console.log(posted.timestamp)

      const newReplies = [...replies, posted]
      setReplies(newReplies)
    },[posted])


    return (
        <div className='feed'>
            {(id?.length > 0) ? (<></>):(<div className='feed__header'>                
                <FeedHeader />

            </div>)}
            {id || location.pathname.includes(`/posts/`) || location.pathname.includes(`/Explore/`) ? (<></>):(
                <ToggleButtonGroup
                    color="secondary"
                    value={filter}
                    exclusive
                    onChange={handleChange}
                    aria-label="Filters"
                    >
                    <ToggleButton value="following">following</ToggleButton>
                    <ToggleButton value="recent">most recent</ToggleButton>
                    <ToggleButton value="shared">shared</ToggleButton>

                </ToggleButtonGroup>)}
                
            <FlipMove id="flipmove">

                {posts?.map(post => {
                    let repliesToThisPost = []
                    const hasReplies = () => {
                      if (typeof replies !== "undefined") {

                        replies?.forEach((reply) => {
                            
                              if (reply.replyingToPost === post.id) {
                                  repliesToThisPost.push(reply)
                              }
                          })
                      }
                    }
                    hasReplies()

                    const isItLiked = () => {
                      let newArray = post?.likedBy?.filter((thing) => 
                        (
                          thing.uid === user.uid
                        ))
                        if (newArray?.length > 0) {
                          return true
                        } else {
                          return false
                        }
                    }
                    const isItBoosted = () => {
                      let newArray = post?.boostedBy?.filter((thing) => 
                        (
                          thing.uid === user.uid
                        ))
                        if (newArray?.length > 0) {
                          return true
                        } else {
                          return false
                        }
                    }
                    

                    return (<div key={post.id} className="feed__tray">
                                <Post
                                boostedByUser={isItBoosted()}
                                boosts={post.boosts}
                                themes={post.themes}
                                repliesNum={repliesToThisPost.length}
                                emoji={post.emoji}
                                likes={post.like}
                                likedByUser={isItLiked()}
                                uid={post.uid}
                                id={post.id}
                                pkey={post.id}
                                text={post.text}
                                avatar={post.avatar}
                                displayName={post.displayName}
                                userName={post.userName}
                                image={post.image}
                                badge={post.badge}
                                timestamp={`${post?.timestamp?.toDate().toLocaleTimeString()}  ${post?.timestamp?.toDate().toLocaleDateString()}`}
                            />
                            
                                <div className="post__replies">
                                
                                    <Replies replies={repliesToThisPost} />
                                </div>
                            
                       
                        </div>)

                })

            }          
            </FlipMove> 
            <div style={{ textAlign: "center" }}>
                {nextPosts_loading ? (
                <p className="captionText" style={{color: "var(--text-muted)", paddingBottom: "12px"}}>Loading..</p>
                ) : location.pathname.includes(`/posts/`) ? (
                <span className="captionText" style={{color: "var(--text-muted)", paddingBottom: "12px"}}>🌇</span>
                ) : typeof lastKeyP === "object" ? (
                  <><Button onClick={fetchMorePosts}>Load More</Button></>
                  ) :(
                  <span className="captionText" style={{color: "var(--text-muted)", paddingBottom: "12px"}}>🌇</span>
                )}
            </div>
        </div>
    )
}

export default Feed
