import React, { useState, useEffect } from 'react'
import './PostForm.css'
import { Avatar, Button, IconButton, TextField } from '@mui/material/'
import { Info } from '@mui/icons-material/';
import db from './firebase'
import { collection, addDoc, getDoc, setDoc, increment, doc, Timestamp, } from "firebase/firestore";
import { useStateValue } from "./StateProvider";
import { actionTypes } from './reducer';
import CheatSheet from './CheatSheet.js'
import Emoji from './Emoji'


function PostForm({ id, isReply, editing, replying, replyingToUser, replyingToUserName, replyingToPost, onSubmit }) {
    const [loveLetter, setLoveLetter] = useState('')
    const [{ user, profile, posted }, dispatch] = useStateValue();
    const [info, setInfo] = useState(false)
    useEffect(()=>{
        if (replying === true) {
            setLoveLetter(`@${replyingToUserName}`)
        }
        if (editing === true) {
            const whichSwitch = () => {
                if (isReply) {
                    return 'replies'
                } else {
                    return 'posts'
                }
            }
            const targetDoc = doc(db, `/${whichSwitch()}/${id}`)
            getDoc(targetDoc).then((doc)=> {
                setLoveLetter(doc.data().text)
            })

        }
    },[replying, editing])
    const sendLoveLetter = (e) => {
        
        e.preventDefault()
        const postedAt = Timestamp.now().toDate()
        if (!replying && !editing) {
            const payload = {
                displayName: user.displayName,
                userName: user.email,
                verified: true,
                text: loveLetter,
                avatar: profile.avatar,
                timestamp: postedAt,
                uid: {uid: user.uid, displayName: user.displayName},
                boostedBy: [],
                boosts: 0,
                likedBy: [],
                like: 0,
                replies: 0,
                emoji: profile.emoji,
                themes:[]
            }
            addDoc(collection(db, "posts"), payload)
            dispatch(
                {
                    type: actionTypes.SET_POSTED,
                    posted: payload
                }
            )
        } else if (replying) {
            const payload = {
                displayName: user.displayName,
                userName: user.email,
                verified: true,
                text: loveLetter,
                avatar: profile.avatar,
                timestamp: postedAt,
                uid: {uid: user.uid, displayName: user.displayName},
                likedBy: [],
                replyingToPost: replyingToPost,
                replyingToUser: replyingToUser,
                isReply: replying, 
                emoji: profile.emoji,
                like: 0,
            }
            addDoc(collection(db, "replies"), payload)
            dispatch(
                {
                    type: actionTypes.SET_POSTED,
                    posted: payload
                }
            )
            const postYouAreReplyingToRef = doc(db, "posts", replyingToPost)
            setDoc(postYouAreReplyingToRef, {
                replies: increment(1)
            }, {merge: true})
        } else if (editing) {
            const whichSwitch = () => {
                if (isReply) {
                    return 'replies'
                } else {
                    return 'posts'
                }
            }
            const targetDoc = doc(db, `/${whichSwitch()}/${id}`)
            setDoc(targetDoc, {

                text: loveLetter,
                
            }, {merge: true})
        } 
        
        setLoveLetter("")
        onSubmit()
        
    }

    const cancelLoveLetter = () => {
        console.log('ope, nevermind')
        setLoveLetter('')
        onSubmit()
    }

    const handleInfo = () => {
        setInfo(!info)
    }

    return (
            <form className="postForm">
                <div className='postForm__input'>
                    <div className='postForm__header'>
                        <Avatar src={profile?.avatar} sx={{height: 24, width: 24}} referrerPolicy="no-referrer" />
                        <div><span className='subtitle1'>{user.displayName} </span></div>
                        <Emoji />
                    </div>
                    <div>{replying ? (<span style={{margin:"2px"}} className="captionText">replying to {replyingToUser.displayName}</span>): editing? (<span style={{margin:"20px"}} className='captionText'>editing</span>):(<></>)}</div>
                    <TextField
                        onChange={(e) => setLoveLetter(e.target.value)}
                        value={loveLetter}
                        fullWidth
                        multiline
                        variant="outlined"
                        placeholder={replying? (`@${replyingToUserName}`):(`Penny for your thoughts?`)}
                        type="text"
                        className='postForm__textInput' />
                </div>
                <Button onClick={sendLoveLetter} variant="contained" className='brand__button' >Post</Button>
                <Button onClick={cancelLoveLetter} className='cancel__button'>Cancel</Button>
                <IconButton onClick={handleInfo}>
                    <Info />
                </IconButton>
                {info? (<CheatSheet />):(<></>)}
            </form>
    )
}

export default PostForm
