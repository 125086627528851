import React, {useEffect} from 'react'
import './Explore.css'
import { useNavigate } from 'react-router-dom'

function Explore() {
    const navigate = useNavigate()

    useEffect(()=>{
        navigate(`/Home`)
    })

    return (
        <div className='explore'>

        </div>
    )
}


export default Explore
