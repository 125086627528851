import React from 'react'
import { IconButton } from '@mui/material'
import { DarkMode, LightMode } from '@mui/icons-material'
import { useStateValue } from "./StateProvider"
import './DorkMode.css'
import { db } from "./firebase"
import { doc,  updateDoc } from 'firebase/firestore'

function DorkMode() {
    const [{ user, profile }] = useStateValue()

    const handleDorkMode = () => {
        const myProfile = doc(db, `users/${user.uid}`)
        updateDoc(myProfile, {
          dorkMode: !profile?.dorkMode
        })
        // add dorkmode to user profile settings
        // summon user profile settings to set dorkmode
        
    }
  return (
    <>
      <IconButton aria-label={profile?.dorkMode? ("set dark mode") : ("set light mode")} onClick={handleDorkMode}    >
        {profile?.dorkMode ? (<DarkMode  />): (<LightMode />) }
      </IconButton>
    </>
  )
}

export default DorkMode
