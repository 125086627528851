import React, {useState, useEffect} from 'react'
import { IconButton, Box, Modal } from '@mui/material'
import { HomeOutlined, NotificationsOutlined, PermIdentity, Search, NotificationsActiveOutlined, AddComment } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { useStateValue } from "./StateProvider";
import Sidebar from './Sidebar';
import PostForm from './PostForm';


function FeedHeader() {

    const [{ user, profile }] = useStateValue()

    const [posting, setPosting] = useState(false)

   
    useEffect(()=>{
        console.log(profile?.notifications)
    },[])     

    const handlePostForm = () => {
        setPosting(!posting)
    }

  

    const style = {
        top: "5%",
        left: "50%",
        transform: "translateY(-50%), translateX(-50%)",
        position: 'absolute',
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '100%',
        maxWidth: '600px',
        boxShadow: 24,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
      };

    const navigate = useNavigate()
    const handleNotifications = () => {
        navigate('/Notifications')
    }
    const handleProfile = () => {
        navigate(`/Profile/${user.uid}`)
    }
   
    const handleHome = () => {
        navigate('/Home')
    }
    const handleSearch = () => {
        navigate('/Search')
    }
  return (
    <div className='feed__header' >

                <IconButton onClick={handleHome}>
                    <HomeOutlined />
                </IconButton>
                <IconButton onClick={handleNotifications}>
                    {typeof profile?.notifications !== "undefined" && profile?.notifications?.length > 0 ? (<NotificationsActiveOutlined />):(<NotificationsOutlined />)}
                </IconButton>
                <IconButton >
                    <Search onClick={() =>{handleSearch()}} />
                </IconButton>
                <IconButton onClick={handleProfile}>
                    <PermIdentity />
                </IconButton>
                <IconButton  onClick={()=>{handlePostForm()}}>
                    <AddComment />
                </IconButton>

                <Sidebar />

                    <Modal
                        open={posting}
                        onClose={handlePostForm}
                        >
                        <Box sx={style}>
                            <PostForm onSubmit={handlePostForm} />                    
                        </Box>
                    </Modal>
            </div>
  )
}

export default FeedHeader
