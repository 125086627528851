import React, { useState, useEffect } from 'react'
import { useStateValue } from "./StateProvider"
import { useNavigate, useLocation, useParams, Link } from 'react-router-dom'
import db from './firebase'
import { arrayUnion, arrayRemove, setDoc, query, where, getDocs, doc, getDoc, updateDoc, collection } from "firebase/firestore"; 
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import {IconButton, Avatar, Button, TextField, Menu, MenuItem, Modal, Box} from '@mui/material'
import { InsertPhoto, AddAPhoto, Check, Cancel, KeyboardArrowDown } from '@mui/icons-material'
import { actionTypes } from './reducer';
import DescriptionForm from './DescriptionForm.js'
import Feed from './Feed'
import Emoji from './Emoji'
import ProfileChipList from './ProfileChipList.js'
import FeedHeader from './FeedHeader.js'
import './Profile.css'
import './Feed.css'

function Profile() {
    const params = useParams()    
    const target = params.target
    const [pageOption, setPageOption] = useState('feed')
    const [pageProfile, setProfile] = useState()
    const [following, setFollowing] = useState()
    const [avatarEditing, setAvatarEditing]= useState(false)
    const [bannerEditing, setBannerEditing]= useState(false)
    const [avatarURL, setAvatarURL] = useState(pageProfile?.avatar)
    const [bannerURL, setBannerURL] = useState(pageProfile?.banner)

    const [{ user, posted, profile }, dispatch] = useStateValue();

    const navigate = useNavigate()

    
    useEffect(()=>{
        console.log('refreshing')
    },[avatarURL, bannerURL, profile, posted, target, pageOption, following])

    useEffect(()=>{

        if (target.includes("@")) {
            
            getDocs(query(collection(db, "users"), where("email", "==", target.slice(1)))).then((docs)=>{
                docs.forEach((doc)=>{
                    navigate(`/Profile/${doc.id}`)
                })
            })
        } else {
            console.log(target)
            const targetUserDoc = doc(db, `/users/${params.target}`)
            const payload = {
                uid: user?.uid,
                displayName: user?.displayName
            }   
            getDoc(targetUserDoc)
                .then((response)=>{
                    setProfile(response.data())
                    let amFollowing = false
                    const filter = response.data().followers.filter(thing => (thing !== payload))
                    console.log('filter: ', filter)
                    if (filter.length > 0 ) {
                        amFollowing = true 
                    }
                    setFollowing(amFollowing)
                
            })
        }

    },[target, profile])



    const handleFollow = () => {
        const myDoc = doc(db, `/users/${user.uid}`)
        const toFollow = doc(db, `/users/${target}`)
        let myPayload = {uid: user?.uid, displayName: user?.displayName}
        let followingPayload = {uid: target, displayName: pageProfile?.displayName}
        if (following) {
                setDoc(toFollow, {followers: arrayRemove(myPayload)}, {merge:true})
                setDoc(myDoc, {following: arrayRemove(followingPayload)}, {merge:true})
                console.log('unfollow')
                setFollowing(false)
            } else {
                setDoc(toFollow, {followers: arrayUnion(myPayload),}, {merge:true})
                setDoc(myDoc, {following: arrayUnion(followingPayload)}, {merge:true})
                console.log('followed')
                setFollowing(true)
            }
            dispatch(
                {
                    type: actionTypes.SET_POSTED,
                    posted: posted+1
                }
            )

        }
    



    const handleBanner = (option) =>{
        if (option === false) {
            setBannerEditing(!bannerEditing)
            return
        } else if (bannerEditing) {
            const myProfile = doc(db, `users/${user.uid}`)
            updateDoc(myProfile, {
              banner: bannerURL
            })
            setBannerEditing(!bannerEditing)    
        } else {
            setBannerEditing(!bannerEditing)
        }
        dispatch(
            {
                type: actionTypes.SET_POSTED,
                posted: posted+1
            }
        )
        
    }
    const handleAvatar = (option) =>{
        if (option === false) {
            setAvatarEditing(!avatarEditing)
            return
        } else if (avatarEditing) {
            const myProfile = doc(db, `users/${user.uid}`)
            updateDoc(myProfile, {
              avatar: avatarURL
            })
            setAvatarEditing(!avatarEditing)    
        } else {
            setAvatarEditing(!avatarEditing)
        }
        dispatch(
            {
                type: actionTypes.SET_POSTED,
                posted: posted+1
            }
        )
       
    }

    const handlePageOption = (popup, newOption) => {
        if (newOption !== null) {
            setPageOption(newOption)
            popup.close()
        }
        };

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "80%",
        maxWidth: "600px",
        bgcolor: 'var(--surface)',
        boxShadow: 24,
        p: 4,
    }


    return (
        <div className="profile">

            <FeedHeader />

            <div className='profile__container' style={{backgroundImage: `url(${pageProfile?.banner})`, backgroundColor: 'transparent', backgroundSize: 'cover'}} >
                
                
                <div className='profile__headerContainer'>
        
                <div className='profile__header' >
                
                {user.uid === target? (
                    <div className="bannerTools">
                    <IconButton onClick={handleAvatar}>
                        <AddAPhoto sx={{height: "16px", width: "16px"}} />
                    </IconButton>
                    <IconButton onClick={handleBanner}>
                        <InsertPhoto sx={{height: "16px", width: "16px"}} />
                    </IconButton>
                    <Modal
                        open={avatarEditing}
                        onClose={handleAvatar}
                        >
                            <Box sx={modalStyle}>
                                <TextField
                                    helperText="Paste a link to an image to use it as your profile picture"
                                    fullWidth
                                    label="Avatar URL"
                                    placeholder="https://www.example.com/hi.jpg"
                                    onChange={(e)=>setAvatarURL(e.target.value)} 
                                />
                                <div className="updateAvatarButtons">
                                    <IconButton type="submit" onClick={handleAvatar}><Check sx={{height: "14px", width: "14px"}}/></IconButton>
                                    <IconButton type="submit" onClick={()=>{handleAvatar(false)}}><Cancel sx={{height: "14px", width: "14px"}}/></IconButton>
                                </div>
                            </Box>

                    </Modal>
                    <Modal
                        open={bannerEditing}
                        onClose={handleBanner}
                        >
                            <Box sx={modalStyle}>
                                <TextField
                                    helperText="Paste a link to an image to use it as your profile banner"
                                    fullWidth
                                    label="Banner URL"
                                    placeholder="https://www.example.com/hi.jpg"
                                    onChange={(e)=>setBannerURL(e.target.value)} 
                                />
                                <div className="updateBannerButtons">
                                    <IconButton type="submit" onClick={handleBanner}><Check sx={{height: "14px", width: "14px"}}/></IconButton>
                                    <IconButton type="submit" onClick={()=>{handleBanner(false)}}><Cancel sx={{height: "14px", width: "14px"}}/></IconButton>
                                </div>
                            </Box>
                    </Modal>

                    </div>
                ):(
                <></>
                )}
                    <Avatar
                        referrerPolicy="no-referrer"
                        src={pageProfile?.avatar}
                        alt={pageProfile?.displayName}
                        sx={{ width: 48, height: 48 }}
                    />
                    
                    
                    
                    <h2 className="subtitle1">
                        {pageProfile?.displayName} {user.uid === target ? (
                    <>
                        <Emoji />
                    </>
                    ):(
                    <>
                        {pageProfile?.emoji}
                    </>)}                           
                    </h2>
                    <p className='captionText'>
                        <Link
                            to='#'
                            onClick={(e)=>{
                                window.location.href = `mailto:${pageProfile?.email}`;
                                e.preventDefault() 

                            }}
                            >
                                                        {pageProfile?.email}
                        </Link>
                    </p>
                    {user.uid === target? (
                        <DescriptionForm description={pageProfile?.description}/>
                        ):(
                        <div className="profile__headerDescription"> 
                            {
                            typeof pageProfile?.description !== "undefined" ? (<p  className='captionText'>{pageProfile?.description}</p>
                            ):(
                            <p className='captionText'>A very happy young person looking forward to a bright and wonderful future</p>)
                            }
                        </div>
                        )}


                    <Button onClick={handleFollow} variant="text" >{typeof following === "undefined" ? ('Loading') : following === true? ('Unfollow'):('Follow')}</Button>
                </div>

                </div>

                        
            </div>
            <div className='profile__headerOptions'>

            

            <PopupState variant="popover" popupId="demo-popup-menu">
                {(popupState) => (
                    <React.Fragment>
                    <Button fullWidth variant="outlined" {...bindTrigger(popupState)} endIcon={<KeyboardArrowDown />}>
                        {pageOption}
                    </Button>
                    <Menu {...bindMenu(popupState)}>
                        <MenuItem onClick={()=>{handlePageOption(popupState, 'feed')}}>posts {pageProfile?.posts} </MenuItem>
                        <MenuItem onClick={()=>{handlePageOption(popupState, 'followers')}}>followers: {pageProfile?.followers?.length} </MenuItem>
                        <MenuItem onClick={()=>{handlePageOption(popupState, 'following')}}>following: {pageProfile?.following?.length}</MenuItem>
                        {user.uid === target ? (<MenuItem onClick={()=>{handlePageOption(popupState, 'blockList')}}>
                            blocked: {pageProfile?.blocking?.length}
                        </MenuItem>):([])}
                    </Menu>
                    </React.Fragment>
                )}
            </PopupState>
            </div>

            { typeof pageProfile?.displayName === "undefined" ? (
                <>Loading...</>
            ):
            pageOption === 'followers' ? (<>
               <ProfileChipList chipList={pageProfile?.followers} />
            </>):
            pageOption === 'following' ? (<>
                <ProfileChipList chipList={pageProfile?.following} />
            </>):
            pageOption === 'blockList' ? (<>
                <ProfileChipList blocked chipList={pageProfile?.blocking}/>
            </>):
            (<>
                <Feed profile id={target} displayName={pageProfile?.displayName}/>
            </>)
            }
            
            
        </div>
    )
}

export default Profile
