import React, {useState, useEffect} from 'react';
import './App.css';
import Feed from "./Feed"
import Login from "./Login"
import Profile from "./Profile.js"
import Notifications from "./Notifications.js"
import Messages from "./Messages.js"
import Explore from "./Explore.js"
import BackGround from "./Background.js"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import { ref, onValue, onDisconnect, set } from 'firebase/database'
import { useStateValue } from "./StateProvider"
import { database } from './firebase';
import { AuthProvider } from './Auth';
import PrivateRoute from './PrivateRoute.js'
import { getMessaging, getToken, onMessage  } from "firebase/messaging";
import Search from './Search.js'

function App() {
 // const { REACT_APP_VAPID_KEY } = process.env
  
  const [{ user, profile }] = useStateValue()
  

  const [token, setToken] = useState([])
//get user doc ONCE and
//update state with user settings
// darkmode
// filtersetting
// who following



// this is currently checking the application state and is not a saved user setting
// replace with logic for getting the user profile and checking the settings

 
const messaging = getMessaging()
const showNotification = (payload) => {
  new Notification(payload.notification.title)
}

onMessage(messaging, (payload) => {
  console.log('Message received. ', payload);
  showNotification(payload)
});



useEffect(()=>{
  if (profile?.dorkMode) {
    document.body.classList.add('dorkMode')
  } else {
    document.body.classList.remove('dorkMode')
  }
  if(user) {
    getToken(messaging, { vapidKey: 'BG1cONJDatvT5IOYkyeCfbqKprDP1JRa_BVc2wmvuqNITFSRQuNzkIich-6yONPdQVr2Rf-dO-c9HR4_DHkRNQ8' }).then((currentToken) => {
      if (currentToken) {
        setToken(currentToken)
        console.log('we got it')
        
      } else {
        console.log('No registration token available. Request permission to generate one.');
      }
    }).catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
      // ...
    });
    rtdb_and_local_fs_presence()
  }
  
},[user, profile?.dorkMode, messaging])

  const rtdb_and_local_fs_presence = () => {
    if (user) {
  
      var uid = user?.uid;
      var userStatusDatabaseRef = ref(database, '/status/' + uid);
      var isOfflineForDatabase = {
        state: 'offline',
        email: user?.email,
        uid: uid,
        photoURL: user?.photoURL,
        displayName: user?.displayName,
        notificationsToken: []
      };
      var isOnlineForDatabase = {
        state: 'online',
        email: user?.email,
        uid: uid,
        photoURL: user?.photoURL,
        displayName: user?.displayName,
        notificationsToken: token
      };
      const connectedRef = ref(database, ".info/connected");
      //detect connection state
      onValue(connectedRef, (snap) => {
        if (snap.val() === false) {
          console.log("user is offline")
          set(userStatusDatabaseRef, isOfflineForDatabase)
          return
        }
        //set(ref(database, userStatusDatabaseRef), isOfflineForDatabase)
        onDisconnect(userStatusDatabaseRef).set(isOfflineForDatabase)
          .then(function () {
            set(userStatusDatabaseRef, isOnlineForDatabase)
            console.log("user is online")
          })
      })
    }
  }
  
 
  return (
    <div className="app">
      
    <AuthProvider>
      <Router>
          <div className="loggedIn">
            


            <Routes>
              <Route path="/login" element={<Login />} />

              <Route path='/' element={<PrivateRoute/>}>
                <Route path='/' element={<Feed/>}/>
              </Route>
              <Route path='/Home' element={<PrivateRoute/>}>
                <Route path='/Home' element={<Feed/>}/>
              </Route>
              <Route path='/Profile' element={<PrivateRoute/>}>
                <Route path='/Profile' element={<Profile />}/>
              </Route>
              <Route path='/Profile/:target' element={<PrivateRoute/>}>
                <Route path='/Profile/:target' element={<Profile />}/>
              </Route>              
              <Route path='/Notifications' element={<PrivateRoute/>}>
                <Route path='/Notifications' element={<Notifications />}/>
              </Route>
              <Route path='/Messages' element={<PrivateRoute/>}>
                <Route path='/Messages' element={<Messages />}/>
              </Route>
              <Route path='/Explore' element={<PrivateRoute/>}>
                <Route path='/Explore' element={<Explore />}/>
              </Route>
              <Route path='/Search' element={<PrivateRoute/>}>
                <Route path='/Search' element={<Search />}/>
              </Route>
              <Route path='/:roomId' element={<PrivateRoute/>}>
                <Route path='/:roomId' element={<Feed />}/>
              </Route>
              <Route path='/posts/:postId' element={<PrivateRoute/>}>
                <Route path='/posts/:postId' element={<Feed />}/>
              </Route>
              <Route path='/Explore/:topic' element={<PrivateRoute/>}>
                <Route path='/Explore/:topic' element={<Feed />}/>
              </Route>
              {/* <Route path="/profile" element={<Profile />} /> */}
            </Routes>
          </div>
      </Router></AuthProvider>

      {/* React-Router -> Chat Screen */}


    </div>
  );
}

export default App;
