import React, {useState} from 'react'
import './Note.css'
import { Favorite, EmojiPeople, Check, MoreHoriz, Forum, PersonAdd, Reply } from '@mui/icons-material' 
import { Button, IconButton, Menu, MenuItem} from '@mui/material'
import db from './firebase'
import { doc, setDoc, arrayRemove } from "firebase/firestore"; 
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import { useNavigate, useLocation, Link } from 'react-router-dom'

function Note({title, body, noteIndex, postId, user, type, uid, displayName}) {
  const location = useLocation()
  const navigate = useNavigate()

  const [read, setRead] = useState(false)

  const handleRead = () => {
      let payload = {
        notification: {
          displayName: displayName,
          uid: uid,
          type: type,
          id: postId,
          title: title,
          body: body
        }
      }
      
      const targetDoc = doc(db, `/users/${user.uid}/`)
      setDoc(targetDoc, {
        notifications: arrayRemove(payload) 
      }, {merge: true}) 
      setRead(true)
  }

  const handleClickNav = (clicked_id) => {
    console.log(`You clicked ${clicked_id}`)
    if (location.pathname !== `/posts/${clicked_id}`) {
        navigate(`/posts/${clicked_id}`)
    } else {
        console.log(`oh`)
    }
}


  return (
    <div className={`note ${read? ('read'):('unread')}`} key={noteIndex}>
        
        
        <div className='note__icon'>
            {type === 'like' ? 
              (<Favorite />) : 
            type === 'boost' ? 
              (<Forum/>): 
            type === 'reply' ? 
              (<Reply />): 
            type === 'follower' ? 
              (<PersonAdd />):
              (<EmojiPeople />)}
            
        </div>


        <div className='note__body'>
            <p className='body2'>{type === 'reply' ? (<><Link to ={`/posts/${postId}`} className='subtitle2'>Your post</Link> has</>):(<Link to={`/Profile/${uid}`} className="subtitle2">{displayName}</Link> )} {body}</p>
        </div>


        <div className='note__tools'>
            
            <div className="post__footerMore">
              <PopupState variant="popover" popupId="notification-popup-menu">
                  {(popupState) => (
                      <React.Fragment>
                        
                        <IconButton {...bindTrigger(popupState)} sx={{cursor:"pointer"}} aria-label="notification options">
                            <MoreHoriz  />
                        </IconButton>                                        
                        
                        
                        <Menu {...bindMenu(popupState)} className="post__contextMenu">
                          {type === 'follower' ? ([]):(

                            <MenuItem component={Link} to={`/posts/${postId}`} onClick={()=>{handleClickNav(postId)}}>
                              <span className="hoverme" >view post</span>
                            </MenuItem>                                    
                              
                          )}
                          <MenuItem>
                            block
                          </MenuItem>                
                        
                        </Menu>
                      </React.Fragment>

                  )}
              </PopupState>
            </div>
            <Button onClick={()=>handleRead()}><span className='captionText'> <Check /> </span></Button>

            
            
            
            
            
        </div>
    </div>
  )
}

export default Note
