import React from 'react'
import './Messages.css'

function Messages() {
    return (
        <div className='messages'>

        </div>
    )
}

export default Messages
